import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cls from './AgencyPage.module.scss';
import { TopBlock } from '../../components/TopBlock/TopBlock';
import image from '../../assets/images/top/PicTopCustoms.png';
import { YellowTitle } from '../../components/UI/MainTitle/MainTitle';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { Title } from '../../components/UI/Title/Title';
import { CardListItem } from '../../components/UI/CardListItem/CardListItem';
import icon1 from '../../assets/icons/Check.svg';
import { BlockBlack } from '../../components/BlockBlack/BlockBlack';
import { CheckList } from '../../components/CheckList/CheckList';

const topBlockList = ['AgencyPage.TopBlock.text'];
const docsList = [
  'AgencyPage.CheckList.l1',
  'AgencyPage.CheckList.l2',
  'AgencyPage.CheckList.l3',
  'AgencyPage.CheckList.l4',
  'AgencyPage.CheckList.l5',
  'AgencyPage.CheckList.l6'
];

export const AgencyPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <TopBlock
        image={image}
        topText={t('AgencyPage.TopBlock.topText')}
        list={topBlockList}
      >
        <YellowTitle>{t('AgencyPage.TopBlock.TitleColor')}</YellowTitle>{' '}
        {t('AgencyPage.TopBlock.Title')}
      </TopBlock>

      <WidthLimiter>
        <div className={cls.offer}>
          <Title>{t('AgencyPage.Offer.Title')}</Title>
          <ul className={cls.list}>
            <li>
              <CardListItem title={t('AgencyPage.Offer.li1')} icon={icon1} />
            </li>
            <li>
              <CardListItem title={t('AgencyPage.Offer.li2')} icon={icon1} />
            </li>
            <li>
              <CardListItem title={t('AgencyPage.Offer.li3')} icon={icon1} />
            </li>
            <li>
              <CardListItem title={t('AgencyPage.Offer.li4')} icon={icon1} />
            </li>
          </ul>
        </div>
      </WidthLimiter>

      <BlockBlack text={t('AgencyPage.Risks.text')}>
        <Title>
          <span>{t('AgencyPage.Risks.TitleColor')}</span>{' '}
          {t('AgencyPage.Risks.Title')}
        </Title>
      </BlockBlack>

      <CheckList
        title={t('AgencyPage.CheckList.Title')}
        text={t('AgencyPage.CheckList.text')}
        list={docsList}
        listTitle={t('AgencyPage.CheckList.listTitle')}
      />
    </div>
  );
};
