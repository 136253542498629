import React from 'react';
import cls from './MainTitle.module.scss';

interface YellowTitleProps {
  children: React.ReactNode;
}

export const YellowTitle = ({ children }: YellowTitleProps) => {
  return (
    <span className={cls.yellowTitle}>
      <span>{children}</span>
      <div></div>
    </span>
  );
};

interface MainTitleProps {
  children: React.ReactNode;
}

export const MainTitle = ({ children }: MainTitleProps) => {
  return <h1 className={cls.mainTitle}>{children}</h1>;
};
