import { WidthLimiter } from '../UI/WidthLimiter';
import { CustomLink } from '../CustomNavLink/CustomNavLink';
import { navLinks } from '../../app/configs/navLinks';
import { useTranslation } from 'react-i18next';
import { OrderForm } from '../OrderForm/OrderForm';
import logo from '../../assets/LogoGtsWhite.png';
import cls from './Footer.module.scss';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <WidthLimiter>
        <div className={cls.container}>
          <div className={cls.form} id="contactForm">
            <div className={cls.formLeft}>
              <div className={cls.formLeftText}>
                <h2>+996 998-233-425</h2>
                <div>support@globaltradesolutions.kg</div>
              </div>
              <p>{t('Footer.form.text')}</p>
            </div>
            <OrderForm />
          </div>
          <div className={cls.divider}></div>
          <div className={cls.footer}>
            <div className={cls.top}>
              <img src={logo} alt="logo" className={cls.logoMobile} />
              <ul className={cls.nav}>
                {navLinks.map((item) => (
                  <CustomLink key={item.to} to={item.to}>
                    {t(item.label)}
                  </CustomLink>
                ))}
              </ul>

              <div className={cls.footerRight}>
                <div className={cls.info}>
                  <p>{t('Footer.info.text2')}</p>
                  <p>{t('Footer.info.text3')}</p>
                </div>
                <div className={cls.info}>
                  <img src={logo} alt="logo" />
                  <p>{t('Footer.info.text1')}</p>
                </div>
              </div>
            </div>

            <div className={cls.bottom}>
              <p>{t('Footer.bottom.text1')}</p>
              <p>{t('Footer.bottom.text2')}</p>
            </div>
          </div>
        </div>
      </WidthLimiter>
    </footer>
  );
};
