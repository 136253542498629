export const Paths = {
  APP: '/',
  LOGISTICS: '/logistics',
  CUSTOMS: '/customs',
  CONSULTING: '/consulting',
  AGENCY: '/agency',
  CONTACTS: '/contacts',
  ADDRESS: 'address',
  REQUISITES: 'requirites',
  NOT_FOUND: '*'
} as const;
