import React from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import { Text } from '../UI/Text/Text';
import { useTranslation } from 'react-i18next';
import cls from './BlockListBlue.module.scss';

interface BlueListItem {
  title?: string;
  text: string;
}

interface Props {
  list: BlueListItem[];
  text?: string;
  children: React.ReactNode;
}

export const BlockListBlue = ({ list, text, children }: Props) => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.text}>
          {children}
          {text && <p>{text}</p>}
        </div>
        <ul className={cls.list}>
          {list.map((item, i) => (
            <li key={i}>
              <div className={cls.item}>
                {item.title && <h6>{item.title}</h6>}
                <Text>{t(item.text)}</Text>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </WidthLimiter>
  );
};
