import React from 'react';
import cls from './CardListItem.module.scss';
import { Text } from '../Text/Text';

type CardListProps = {
  title: string;
  text?: string;
  icon: string;
};

export const CardListItem = ({ icon, title, text }: CardListProps) => {
  return (
    <div className={cls.container}>
      <img src={icon} alt="icon card" />
      <div className={cls.text}>
        <h4>{title}</h4>
        {text && <Text>{text}</Text>}
      </div>
    </div>
  );
};
