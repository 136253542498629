import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TopBlock } from '../../components/TopBlock/TopBlock';
import { Stages } from '../../components/CustomsPage/Stages';
import { CheckList } from '../../components/CheckList/CheckList';
import { YellowTitle } from '../../components/UI/MainTitle/MainTitle';
import cls from './CustomsPage.module.scss';
import image from '../../assets/images/top/PicTopCustoms.png';

const docsList = [
  'CustomsPage.CheckList.list.li1',
  'CustomsPage.CheckList.list.li2',
  'CustomsPage.CheckList.list.li3',
  'CustomsPage.CheckList.list.li4',
  'CustomsPage.CheckList.list.li5',
  'CustomsPage.CheckList.list.li6',
  'CustomsPage.CheckList.list.li7',
  'CustomsPage.CheckList.list.li8',
  'CustomsPage.CheckList.list.li9',
  'CustomsPage.CheckList.list.li10',
  'CustomsPage.CheckList.list.li11',
  'CustomsPage.CheckList.list.li12',
  'CustomsPage.CheckList.list.li13',
  'CustomsPage.CheckList.list.li14'
];

const topBlockList = ['CustomsPage.TopBlock.text'];

export const CustomsPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <TopBlock
        image={image}
        topText={t('CustomsPage.TopBlock.topText')}
        list={topBlockList}
      >
        <YellowTitle>{t('CustomsPage.TopBlock.TitleColor')}</YellowTitle>{' '}
        {t('CustomsPage.TopBlock.Title')}
      </TopBlock>

      <Stages />
      <CheckList title={t('CustomsPage.CheckList.Title')} list={docsList} />
    </div>
  );
};
