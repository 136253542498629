import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cls from './ContactsPage.module.scss';
import {
  MainTitle,
  YellowTitle
} from '../../components/UI/MainTitle/MainTitle';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import { CustomNavLink } from '../../components/CustomNavLink/CustomNavLink';
import { Paths } from '../../app/providers/router/Paths';
import { Outlet, useNavigate } from 'react-router-dom';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { navContacts } from '../../app/configs/navLinks';

export const ContactsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(Paths.ADDRESS);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.topBlock}>
          <MainTitle>
            <YellowTitle>Контакты</YellowTitle> Global Trade Solutions
          </MainTitle>
          <p>Свяжитесь с нами любым удобным способом</p>
        </div>

        <div className={cls.content}>
          <div className={cls.left}>
            <div className={cls.leftNav}>
              {navContacts.map((contact) => (
                <CustomNavLink to={contact.to} key={contact.to}>
                  {t(contact.label)}
                </CustomNavLink>
              ))}
            </div>
            <Outlet />
          </div>
          <YMaps>
            <div className={cls.map}>
              <Map
                defaultState={{ center: [42.865285, 74.617499], zoom: 16 }}
                height={'100%'}
                width={'100%'}
              >
                <Placemark
                  options={{ iconColor: '#ff0000' }}
                  defaultGeometry={[42.865285, 74.617499]}
                />
              </Map>
            </div>
          </YMaps>
        </div>
      </div>
    </WidthLimiter>
  );
};
