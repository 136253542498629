import { Paths } from '../providers/router/Paths';

export const navLinks = [
  {
    to: Paths.APP,
    label: 'Nav.main'
  },
  {
    to: Paths.LOGISTICS,
    label: 'Nav.logistics'
  },
  {
    to: Paths.CUSTOMS,
    label: 'Nav.Customs'
  },
  {
    to: Paths.CONSULTING,
    label: 'Nav.Consulting'
  },
  {
    to: Paths.AGENCY,
    label: 'Nav.agents'
  },
  {
    to: Paths.CONTACTS,
    label: 'Nav.contacts'
  }
];

export const navContacts = [
  {
    to: Paths.ADDRESS,
    label: 'Nav.address'
  },
  {
    to: Paths.REQUISITES,
    label: 'Nav.REQUISITES'
  }
];
