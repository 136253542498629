import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TopBlock } from '../../components/TopBlock/TopBlock';
import { Advantages } from '../../components/MainPage/Advantages';
import { BlockListBlue } from '../../components/BlockListBlue/BlockListBlue';
import { Cargo } from '../../components/MainPage/Cargo';
import { FormBlock } from '../../components/MainPage/FormBlock';
import { BlockBlack } from '../../components/BlockBlack/BlockBlack';
import { Title } from '../../components/UI/Title/Title';
import cls from './MainPage.module.scss';
import image from '../../assets/images/top/PicTopMain.png';
import { YellowTitle } from '../../components/UI/MainTitle/MainTitle';

const activityList = [
  {
    text: 'MainPage.Activity.liText1'
  },
  {
    text: 'MainPage.Activity.liText2'
  },
  {
    text: 'MainPage.Activity.liText3'
  },
  {
    text: 'MainPage.Activity.liText4'
  }
];

const topBlockList = [
  'MainPage.TopBlock.li1',
  'MainPage.TopBlock.li2',
  'MainPage.TopBlock.li3'
];

export const MainPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <TopBlock
        image={image}
        topText={t('MainPage.TopBlock.topText')}
        list={topBlockList}
      >
        {t('MainPage.TopBlock.title1')}
        <YellowTitle>{t('MainPage.TopBlock.titleColor')}</YellowTitle>
        {t('MainPage.TopBlock.title2')}
      </TopBlock>
      <Advantages />
      <BlockBlack text={t('MainPage.Guarantees.text')}>
        <Title>
          {t('MainPage.Guarantees.Title')}{' '}
          <span>{t('MainPage.Guarantees.TitleColor')}</span>
        </Title>
      </BlockBlack>
      <div className={cls.activity}>
        <BlockListBlue list={activityList}>
          <Title>
            {t('MainPage.Activity.Title')}{' '}
            <span>{t('MainPage.Activity.TitleColor')}</span>{' '}
            {t('MainPage.Activity.Title2')}
          </Title>
        </BlockListBlue>
      </div>
      <FormBlock />
      <Cargo />
    </div>
  );
};
