import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TopBlock } from '../../components/TopBlock/TopBlock';
import { CheckList } from '../../components/CheckList/CheckList';
import { BlockBlack } from '../../components/BlockBlack/BlockBlack';
import { Title } from '../../components/UI/Title/Title';
import { CardListItem } from '../../components/UI/CardListItem/CardListItem';
import { WidthLimiter } from '../../components/UI/WidthLimiter';
import icon1 from '../../assets/icons/Check.svg';
import cls from './FinancePage.module.scss';
import image from '../../assets/images/top/PicTopFinance.png';
import { YellowTitle } from '../../components/UI/MainTitle/MainTitle';

const docsList = [
  'ConsultingPage.CheckList.l1',
  'ConsultingPage.CheckList.l2',
  'ConsultingPage.CheckList.l3',
  'ConsultingPage.CheckList.l4',
  'ConsultingPage.CheckList.l5'
];

const topBlockList = ['ConsultingPage.TopBlock.text'];

export const FinancePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cls.container}>
      <TopBlock
        image={image}
        topText={t('ConsultingPage.TopBlock.topText')}
        list={topBlockList}
      >
        <YellowTitle>{t('ConsultingPage.TopBlock.TitleColor')}</YellowTitle>{' '}
        {t('ConsultingPage.TopBlock.Title')}
      </TopBlock>

      <WidthLimiter>
        <div className={cls.solutions}>
          <Title>{t('ConsultingPage.Solutions.Title')}</Title>
          <ul className={cls.list}>
            <li>
              <CardListItem
                title={t('ConsultingPage.Solutions.li1')}
                icon={icon1}
              />
            </li>
            <li>
              <CardListItem
                title={t('ConsultingPage.Solutions.li2')}
                icon={icon1}
              />
            </li>
            <li>
              <CardListItem
                title={t('ConsultingPage.Solutions.li3')}
                icon={icon1}
              />
            </li>
            <li>
              <CardListItem
                title={t('ConsultingPage.Solutions.li4')}
                icon={icon1}
              />
            </li>
          </ul>
        </div>
      </WidthLimiter>

      <BlockBlack text={t('ConsultingPage.Protect.text')}>
        <Title>
          <span>{t('ConsultingPage.Protect.TitleColor')}</span>{' '}
          {t('ConsultingPage.Protect.Title')}
        </Title>
      </BlockBlack>

      <CheckList
        title={t('ConsultingPage.CheckList.Title')}
        text={t('ConsultingPage.CheckList.text')}
        list={docsList}
      />
    </div>
  );
};
