import React from 'react';
import { useTranslation } from 'react-i18next';
import { TopBlock } from '../../components/TopBlock/TopBlock';
import { List } from '../../components/LogisticsPage/List';
import { BlockListBlue } from '../../components/BlockListBlue/BlockListBlue';
import { Title } from '../../components/UI/Title/Title';
import { BlockBlack } from '../../components/BlockBlack/BlockBlack';
import { YellowTitle } from '../../components/UI/MainTitle/MainTitle';
import image from '../../assets/images/top/PicTopLogistics.png';
import cls from './LogisticsPage.module.scss';

const priceCargoList = [
  {
    title: 'LogisticsPage.Price.list.title1',
    text: 'LogisticsPage.Price.list.text1'
  },
  {
    title: 'LogisticsPage.Price.list.title2',
    text: 'LogisticsPage.Price.list.text2'
  },
  {
    title: 'LogisticsPage.Price.list.title3',
    text: 'LogisticsPage.Price.list.text3'
  },
  {
    title: 'LogisticsPage.Price.list.title4',
    text: 'LogisticsPage.Price.list.text4'
  }
];

const topList = ['LogisticsPage.TopBlock.text'];

export const LogisticsPage = () => {
  const { t } = useTranslation();

  return (
    <div className={cls.container}>
      <TopBlock
        image={image}
        topText={t('LogisticsPage.TopBlock.topText')}
        list={topList}
      >
        <YellowTitle>{t('LogisticsPage.TopBlock.TitleColor')}</YellowTitle>{' '}
        {t('LogisticsPage.TopBlock.Title')}
      </TopBlock>
      <List />

      <BlockBlack text={t('LogisticsPage.reliability.text')}>
        <Title>
          {t('LogisticsPage.reliability.Title')}{' '}
          <span>{t('LogisticsPage.reliability.TitleColor')}</span>
        </Title>
      </BlockBlack>

      <div className={cls.price}>
        <BlockListBlue
          list={priceCargoList}
          text={t('LogisticsPage.Price.text')}
        >
          <Title>{t('LogisticsPage.Price.Title')}</Title>
        </BlockListBlue>
      </div>
    </div>
  );
};
