import React from 'react';
import cls from './ContactsPage.module.scss';
import { MainTitle } from '../../components/UI/MainTitle/MainTitle';

export const AddressPage = () => {
  return (
    <div className={cls.nested}>
      <MainTitle>Наш адрес</MainTitle>
      <p>
        Кыргызская Республика, город Бишкек, Ленинский район, ул. Турусбекова,
        д. 109/1, этаж 3, каб. 305
      </p>
      <ul className={cls.list}>
        <li>
          <h4>Режим работы</h4>
          <p>09:00 — 18:00</p>
        </li>
        <li>
          <h4>Телефон</h4>
          <p>+996 (998) 233-425</p>
        </li>
        <li>
          <h4>Электронная почта</h4>
          <p>support@globaltradesolutions.kg</p>
        </li>
      </ul>
    </div>
  );
};
