import React from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import cls from './CheckList.module.scss';
import { Title } from '../UI/Title/Title';
import icon from '../../assets/icons/IconCheckSmall.svg';
import { Text } from '../UI/Text/Text';
import { useTranslation } from 'react-i18next';

interface Props {
  list: string[];
  title: string;
  text?: string;
  listTitle?: string;
}

export const CheckList = ({ title, text, list, listTitle }: Props) => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.top}>
          <Title>{title}</Title>
          {text && <p>{text}</p>}
        </div>
        <div className={cls.top}>
          {listTitle && <h4>{listTitle}</h4>}
          <ul className={cls.list}>
            {list.map((item, i) => (
              <li className={cls.item} key={i}>
                <img src={icon} alt="icon check" />
                <Text>{t(item)}</Text>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </WidthLimiter>
  );
};
