import React from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import { Title } from '../UI/Title/Title';
import { CardListItem } from '../UI/CardListItem/CardListItem';
import { useTranslation } from 'react-i18next';
import icon1 from '../../assets/icons/Award.svg';
import cls from './Advantages.module.scss';

export const Advantages = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <Title>{t('MainPage.Advantages.Title')}</Title>
        <ul className={cls.list}>
          <li>
            <CardListItem
              title={t('MainPage.Advantages.list.title1')}
              text={t('MainPage.Advantages.list.text1')}
              icon={icon1}
            />
          </li>
          <li>
            <CardListItem
              title={t('MainPage.Advantages.list.title2')}
              text={t('MainPage.Advantages.list.text2')}
              icon={icon1}
            />
          </li>
          <li>
            <CardListItem
              title={t('MainPage.Advantages.list.title3')}
              text={t('MainPage.Advantages.list.text3')}
              icon={icon1}
            />
          </li>
          <li>
            <CardListItem
              title={t('MainPage.Advantages.list.title4')}
              text={t('MainPage.Advantages.list.text4')}
              icon={icon1}
            />
          </li>
          <li>
            <CardListItem
              title={t('MainPage.Advantages.list.title5')}
              text={t('MainPage.Advantages.list.text5')}
              icon={icon1}
            />
          </li>
        </ul>
      </div>
    </WidthLimiter>
  );
};
