import { createBrowserRouter } from 'react-router-dom';
import { Paths } from './Paths';
import { App } from '../../App';
import { MainPage } from '../../../pages/main/MainPage';
import { LogisticsPage } from '../../../pages/logistics/LogisticsPage';
import { CustomsPage } from '../../../pages/customs/CustomsPage';
import { FinancePage } from '../../../pages/finance/FinancePage';
import { ContactsPage } from '../../../pages/contacts/ContactsPage';
import { AgencyPage } from '../../../pages/agency/AgencyPage';
import { RequisitesPage } from '../../../pages/contacts/Requisites';
import { AddressPage } from '../../../pages/contacts/Address';

export const router = createBrowserRouter([
  {
    path: Paths.APP,
    element: <App />,
    children: [
      {
        index: true,
        element: <MainPage />
      },
      {
        path: Paths.LOGISTICS,
        element: <LogisticsPage />
      },
      {
        path: Paths.CUSTOMS,
        element: <CustomsPage />
      },
      {
        path: Paths.CONSULTING,
        element: <FinancePage />
      },
      {
        path: Paths.AGENCY,
        element: <AgencyPage />
      },
      {
        path: Paths.CONTACTS,
        element: <ContactsPage />,
        children: [
          {
            path: Paths.ADDRESS,
            element: <AddressPage />
          },
          {
            path: Paths.REQUISITES,
            element: <RequisitesPage />
          }
        ]
      }
    ]
  }
]);
