import React from 'react';
import cls from './List.module.scss';
import icon1 from '../../assets/icons/Check.svg';
import { CardListItem } from '../UI/CardListItem/CardListItem';
import { WidthLimiter } from '../UI/WidthLimiter';
import { useTranslation } from 'react-i18next';

export const List = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <ul className={cls.container}>
        <li>
          <CardListItem
            title={t('LogisticsPage.List.title1')}
            text={t('LogisticsPage.List.text1')}
            icon={icon1}
          />
        </li>
        <li>
          <CardListItem
            title={t('LogisticsPage.List.title2')}
            text={t('LogisticsPage.List.text2')}
            icon={icon1}
          />
        </li>
        <li>
          <CardListItem
            title={t('LogisticsPage.List.title3')}
            text={t('LogisticsPage.List.text3')}
            icon={icon1}
          />
        </li>
        <li>
          <CardListItem
            title={t('LogisticsPage.List.title4')}
            text={t('LogisticsPage.List.text4')}
            icon={icon1}
          />
        </li>
        <li>
          <CardListItem
            title={t('LogisticsPage.List.title5')}
            text={t('LogisticsPage.List.text5')}
            icon={icon1}
          />
        </li>
        <li>
          <CardListItem
            title={t('LogisticsPage.List.title6')}
            text={t('LogisticsPage.List.text6')}
            icon={icon1}
          />
        </li>
      </ul>
    </WidthLimiter>
  );
};
