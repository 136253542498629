import React from 'react';
import { Title } from '../UI/Title/Title';
import { OrderForm } from '../OrderForm/OrderForm';
import { WidthLimiter } from '../UI/WidthLimiter';
import { Text } from '../UI/Text/Text';
import { useTranslation } from 'react-i18next';
import image from '../../assets/images/PicMainForm.jpg';
import cls from './FormBlock.module.scss';

export const FormBlock = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <Title>{t('MainPage.FormBlock.Title')}</Title>
        <div className={cls.content}>
          <div className={cls.left}>
            <img src={image} alt="image form" />
          </div>
          <div className={cls.form}>
            <Text>{t('MainPage.FormBlock.text')}</Text>
            <OrderForm />
          </div>
        </div>
      </div>
    </WidthLimiter>
  );
};
