import React from 'react';
import { Title } from '../UI/Title/Title';
import { Text } from '../UI/Text/Text';
import { WidthLimiter } from '../UI/WidthLimiter';
import { useTranslation } from 'react-i18next';
import cls from './Stages.module.scss';

interface IStageItemProps {
  number: number;
  title: string;
  text: string;
}

const StageItem = ({ number, title, text }: IStageItemProps) => {
  const { t } = useTranslation();

  return (
    <li>
      <div className={cls.item}>
        <div className={cls.top}>
          <div className={cls.circle}>{number}</div>
          <div className={cls.line}></div>
        </div>
        <div className={cls.text}>
          <h4>{t(title)}</h4>
          <Text>{t(text)}</Text>
        </div>
      </div>
    </li>
  );
};

export const Stages = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <Title>{t('CustomsPage.Stages.Title')}</Title>
        <ul className={cls.list}>
          <StageItem
            number={1}
            title={t('CustomsPage.Stages.list.title1')}
            text={t('CustomsPage.Stages.list.text1')}
          />
          <StageItem
            number={2}
            title={t('CustomsPage.Stages.list.title2')}
            text={t('CustomsPage.Stages.list.text2')}
          />
          <StageItem
            number={3}
            title={t('CustomsPage.Stages.list.title3')}
            text={t('CustomsPage.Stages.list.text3')}
          />
          <StageItem
            number={4}
            title={t('CustomsPage.Stages.list.title4')}
            text={t('CustomsPage.Stages.list.text4')}
          />
          <StageItem
            number={5}
            title={t('CustomsPage.Stages.list.title5')}
            text={t('CustomsPage.Stages.list.text5')}
          />
        </ul>
      </div>
    </WidthLimiter>
  );
};
