import React from 'react';
import cls from './BlockBlack.module.scss';
import { WidthLimiter } from '../UI/WidthLimiter';
import { Text } from '../UI/Text/Text';

interface Props {
  text: string;
  children: React.ReactNode;
}

export const BlockBlack = ({ text, children }: Props) => {
  return (
    <div className={cls.BlockBlack}>
      <WidthLimiter>
        <div className={cls.container}>
          {children}
          <Text>{text}</Text>
        </div>
      </WidthLimiter>
    </div>
  );
};
