import React from 'react';
import cls from './Button.module.scss';
import { Link } from 'react-router-dom';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  children?: React.ReactNode;
  size?: 'sm' | 'md';
}

export const Button = ({
  children,
  disabled,
  size = 'md',
  ...props
}: Props) => {
  return (
    <button
      className={`${cls.btn} ${cls[size]}`}
      disabled={disabled}
      {...props}
    >
      {disabled ? 'Отправка...' : children}
    </button>
  );
};

interface LinkProps {
  to: string;
  children: React.ReactNode;
  size?: 'sm' | 'md';
  onClick?: () => void;
}

export const LinkButton = ({
  children,
  to,
  onClick,
  size = 'md',
  ...props
}: LinkProps) => {
  return (
    <Link
      to={to}
      className={`${cls.btn} ${cls[size]}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </Link>
  );
};

export const AnchorLink = ({
  children,
  to,
  onClick,
  size = 'md',
  ...props
}: LinkProps) => {
  return (
    <a
      href={to}
      className={`${cls.btn} ${cls[size]}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  );
};
