import React from 'react';
import cls from './ContactsPage.module.scss';
import { MainTitle } from '../../components/UI/MainTitle/MainTitle';

export const RequisitesPage = () => {
  return (
    <div className={cls.nested}>
      <MainTitle>Наши реквизиты</MainTitle>
      <div className={cls.reqText}>ОсОО «Решения для глобальной торговли»</div>
      <ul className={cls.list}>
        <li>
          <p>dfgdfsdfg dsgfsdfgdf</p>
          <p>dfgdfsdfg dsgfsdfgdf</p>
          <p>dfgdfsdfg dsgfsdfgdf</p>
          <p>dfgdfsdfg dsgfsdfgdf</p>
          <p>dfgdfsdfg dsgfsdfgdf</p>
          <p>dfgdfsdfg dsgfsdfgdf</p>
          <p>dfgdfsdfg dsgfsdfgdf</p>
        </li>
      </ul>
    </div>
  );
};
