import { useState } from 'react';
import { WidthLimiter } from '../UI/WidthLimiter';
import { NavLink } from 'react-router-dom';
import { Paths } from '../../app/providers/router/Paths';
import Drawer from 'react-modern-drawer';
import { navLinks } from '../../app/configs/navLinks';
import { CustomNavLink } from '../CustomNavLink/CustomNavLink';
import { LangSelector } from '../LangSelector/LangSelector';
import 'react-modern-drawer/dist/index.css';
import { useTranslation } from 'react-i18next';
import { AnchorLink } from '../UI/Button/Button';
import logo from '../../assets/LogoGts.png';
import { ReactComponent as IconBurger } from '../../assets/burger-icon.svg';
import { ReactComponent as IconBurgerClose } from '../../assets/burger-close.svg';
import cls from './Header.module.scss';

export const Header = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <header className={cls.header}>
      <WidthLimiter>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="top"
          size="auto"
          className={cls.drawer}
          overlayColor={'#FFF'}
          overlayOpacity={0.75}
        >
          <div className={cls.drawerContent}>
            <WidthLimiter>
              <div className={cls.drawerCol}>
                <LangSelector />
                <nav className={cls.navDrawer}>
                  {navLinks.map((item) => (
                    <CustomNavLink
                      key={item.to}
                      to={item.to}
                      onClick={toggleDrawer}
                    >
                      {t(item.label)}
                    </CustomNavLink>
                  ))}
                </nav>

                <ul className={cls.info2}>
                  <li>
                    <a href="tel:+996998233425">+996 998-233-425</a>
                  </li>
                  <li>
                    <a href="mailto:support@globaltradesolutions.kg">
                      support@globaltradesolutions.kg
                    </a>
                  </li>
                </ul>
                <AnchorLink
                  to={'#contactForm'}
                  size={'sm'}
                  onClick={toggleDrawer}
                >
                  {t('Other.Header.buttonText')}
                </AnchorLink>
              </div>
            </WidthLimiter>
          </div>
        </Drawer>
        <div className={cls.head}>
          <div className={cls.container}>
            <NavLink
              to={Paths.APP}
              style={{ display: 'flex' }}
              onClick={closeDrawer}
            >
              <img src={logo} alt={'logo'} className={cls.logo} />
            </NavLink>
            <div className={cls.toolbar}>
              <div className={cls.burgerContainer}>
                <button onClick={toggleDrawer}>
                  {isOpen ? <IconBurgerClose /> : <IconBurger />}
                </button>
              </div>

              <ul className={cls.info}>
                <li>
                  <a href="tel:+996998233425">+996 998-233-425</a>
                </li>
                <li>
                  <a href="mailto:support@globaltradesolutions.kg">
                    support@globaltradesolutions.kg
                  </a>
                </li>
              </ul>

              <div className={cls.actions}>
                <LangSelector />
                <AnchorLink to={'#contactForm'} size={'sm'}>
                  {t('Other.Header.buttonText')}
                </AnchorLink>
              </div>
            </div>
          </div>
          <nav className={cls.nav}>
            {navLinks.map((item) => (
              <CustomNavLink key={item.to} to={item.to}>
                {t(item.label)}
              </CustomNavLink>
            ))}
          </nav>
        </div>
      </WidthLimiter>
    </header>
  );
};
