import React from 'react';
import { AnchorLink } from '../UI/Button/Button';
import { WidthLimiter } from '../UI/WidthLimiter';
import { MainTitle } from '../UI/MainTitle/MainTitle';
import { useTranslation } from 'react-i18next';
import cls from './TopBlock.module.scss';

interface Props {
  topText: string;
  list: string[];
  children: React.ReactNode;
  image: string;
}

export const TopBlock = ({ topText, children, list, image }: Props) => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <div className={cls.left}>
          <div className={cls.leftTop}>
            <p>{topText}</p>
            <MainTitle>{children}</MainTitle>
          </div>
          <ul className={cls.list}>
            {list.map((item, i) => (
              <li key={i}>
                <p>{t(item)}</p>
              </li>
            ))}
          </ul>
          <div className={cls.right2}>
            <img src={image} alt="top image" />
          </div>
          <div className={cls.info}>
            {t('Other.TopBlock.text')}{' '}
            <span>{t('Other.TopBlock.textBold')}</span>
          </div>
          <AnchorLink to={'#contactForm'}>
            {t('Other.TopBlock.buttonText')}
          </AnchorLink>
        </div>
        <div className={cls.right}>
          <img src={image} alt="top image" />
        </div>
      </div>
    </WidthLimiter>
  );
};
