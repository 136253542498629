import React from 'react';
import cls from './Cargo.module.scss';
import { Title } from '../UI/Title/Title';
import { WidthLimiter } from '../UI/WidthLimiter';
import { categories } from '../../app/configs/allCategories';
import { useTranslation } from 'react-i18next';

export const Cargo = () => {
  const { t } = useTranslation();

  return (
    <WidthLimiter>
      <div className={cls.container}>
        <Title>
          <span>{t('MainPage.Cargo.TitleColor')}</span>
          {t('MainPage.Cargo.Title')}
        </Title>
        <ul className={cls.list}>
          {categories.map((category, i) => (
            <li key={i}>
              <div className={cls.item}>
                <img src={category.image} alt="image" />
                <h5>{t(category.text)}</h5>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </WidthLimiter>
  );
};
