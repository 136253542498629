import React, { ReactNode } from 'react';
import cls from './Title.module.scss';

interface Props {
  children: ReactNode;
}

export const Title = ({ children }: Props) => {
  return <h3 className={cls.titleBlock}>{children}</h3>;
};
